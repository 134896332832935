import LanguagesList from '../Components/LanguagesList/LanguagesList';
import Cookie from '../Components/Cookie/Cookie';
import $ from 'jquery';

export default {

	init() {

		LanguagesList.init();
		Cookie.init();

		// alert( window.innerWidth +' '+window.innerHeight);

		function openMobileMenu() {

			var hamburger = document.querySelector('.Header .right__hamburger');

			var menu = document.querySelector('.MobileMenu');
			var menuContent = menu.querySelector('.mobileMenu__content');
			var menuItems = menuContent.querySelectorAll('.menu__item');

			hamburger.addEventListener('click', function(e) {

				e.stopPropagation();

				if( !menu.classList.contains('open') ) {

					menu.classList.add('open');

				} else {

					menu.classList.remove('open');

				}

			});

			document.addEventListener('click', function() {

				if( menu.classList.contains('open') ) {

					menu.classList.remove('open');

				}

			});

			menuContent.addEventListener('click', function(e) {

				e.stopPropagation();

			});

			menuItems.forEach( function( item,index ) {

				item.addEventListener('click', function() {
					menu.classList.remove('open');
				});

			});

		}

		function menuItemScroll() {

			var header = document.querySelector('.Header');
			var items = document.querySelectorAll('nav .menu__item');

			items.forEach( function( item,index ) {

				if( 
					item.getAttribute('href').split('#')[0] == '' && 
					item.getAttribute('href').split('#')[1] &&
					document.querySelector('#'+item.getAttribute('href').split('#')[1])
				) {

					item.addEventListener('click', function(e) {
						
						e.preventDefault();

						if( window.innerWidth <= 960 ) {

							$('html ,body').animate({'scrollTop': (document.querySelector('#'+item.getAttribute('href').split('#')[1] ).offsetTop - header.offsetHeight) }, 'smooth');

						} else {

							$('html ,body').animate({'scrollTop': document.querySelector('#'+item.getAttribute('href').split('#')[1] ).offsetTop }, 'smooth');

						}

					});

				}

			});

		}

		function headerBackground() {

			var header = document.querySelector('.Header.header--absolute');

			if( header ) {

				window.addEventListener('scroll', function(e) {

					if( window.innerWidth <= 960 && window.scrollY > 0 ) {

						header.classList.add('background--white');
							
					} else {

						header.classList.remove('background--white');

					}

				});
				
			}

		}

		headerBackground();
		openMobileMenu();
		menuItemScroll();

	},
	finalize() {

	}

}