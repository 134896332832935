export default {

	init(){

		var container = document.querySelector('.ContactForm');

		if( container ) {

			function callbackFunction( message, status = 'error' ) {

				callback = document.querySelectorAll('.contactForm__callBack');

				callback.forEach( function( single,index ) {
					single.remove();
				});

				if( message ) {

					container.querySelector('.contactForm__agreements').insertAdjacentHTML('afterend', '<p class="contactForm__callBack '+status+'">'+message+'</p>' );

				}

			}

			function validEmail() {

				fields.forEach( function( input, index ) {

					if( input.type == 'email' ) {

						if( input.value && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(input.value) ) {

							validation = true;

						} else {

							callbackFunction(callbackEmail);
							submitButton.classList.remove('disable');

						}

					}

				})

			}

			function validFields() {

				callbackFunction();

				fields.forEach( function( input, index ) {

					if( input.type == 'checkbox' ) {

						if( input.checked == false ) {

							input.classList.add('empty');

						} else {

							input.classList.remove('empty');

						}

					} else {

						if( !input.value ) {

							input.classList.add('empty');

						} else {

							input.classList.remove('empty');

						}

					}

				});

				if( container.querySelectorAll('.formField.empty').length == 0 ) {

					validEmail();

				} else {

					callbackFunction(callbackempty);
					submitButton.classList.remove('disable');

				}

			}

			var submitButton = container.querySelector('.contactForm__submit');

			var fields = container.querySelectorAll('.formField');

			var requestData = [];
			var request;
			var response;
			var validation = false;

			var formsubmit = false;
			var callback = null;
			var callbackSuccess = container.getAttribute('data-success');
			var callbackError = container.getAttribute('data-error');
			var callbackempty = container.getAttribute('data-empty');
			var callbackEmail = container.getAttribute('data-email');

			submitButton.addEventListener('click',function(e) {

				e.preventDefault();

				if( !submitButton.classList.contains('disable') && !formsubmit ) {

					validFields();

					if(	validation ) {

						// -- Request data -- // 

							requestData = [];

							requestData.push( 'action=sendMail' );
							requestData.push( 'security='+container.querySelector('input#authorize').value );
							
							fields.forEach( function( input, index ) {

								if( input.type != 'checkbox' ) {

									requestData.push( input.getAttribute('name')+'='+input.value );

								}

							});

							requestData = requestData.join('&');

						// -- END -- // 

						request = new XMLHttpRequest();

						request.onreadystatechange = function() {

							if( request.responseText ) {

								response = JSON.parse(request.responseText);

								console.log(response);

								if( response.status == 'success' ) {
									
									callbackFunction(callbackSuccess,'success');
									formSubmit = true;

								} else if( response.status == 'error' ) {

									callbackFunction(callbackError, 'error');
									submitButton.classList.remove('disable');

								}

							}

						}

						// Set up our request
						request.open('POST', document.querySelector('footer').getAttribute('data-endpoint') );

						// Add the required HTTP header for form data POST requests
						request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

						// Finally, send our data.
						request.send(requestData);

					}

				}

			});

		}

	},
	finalize(){}

}