import slick from 'slick-carousel';
import $ from 'jquery';

export default {

	init() {

		var container = document.querySelector('section.Slaider');

		if( container ) {

			function slaiderSlickCarousel( container ) {

				$(container.querySelector('.slaider__carousel') ).slick({
					arrows : false,
					slidesToShow : 4,
					slidesToScroll : 1,
					infinite : true,
					rows: 0,
					autoplay: true,
					autoplaySpeed: 1500,
					responsive: [
						{
							breakpoint: 961,
							settings : {
								slidesToShow: 3,
							}
						},
						{
							breakpoint: 641,
							settings : {
								slidesToShow: 2,
							}
						},
						{
							breakpoint: 321,
							settings: {
								slidesToShow: 1
							}
						}

					]
				});

			}

			slaiderSlickCarousel( container );


		}

	},	
	finalize() {

	}

}