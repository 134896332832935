export default {

	init() {

		var container = document.querySelector('.ImageLink');

		if( container ) {

			function imageLinkCarousel( container ) {

				var images = container.querySelectorAll('.images__single');
				var imageCounter = 0;
				var imagesLength = images.length;

				var intervalTime = 2000;

				setInterval(
	
					function() {
						
						images[imageCounter].classList.remove('show');

						imageCounter += 1;

						if( imageCounter >= imagesLength ) {

							imageCounter = 0;

						}

						images[imageCounter].classList.add('show');

					},
					intervalTime
				); 


			}

			imageLinkCarousel( container );

		}

	},
	finalize(){
		// 
	}

}