// Defaults
import './style.scss';
import Router from './scripts/Router';
import LazyLoad from './scripts/lazyLoad';

// Subpage
import Subpage from '../src/Layout/Subpage';

// Pages
import homepage from '../src/Pages/Homepage/Homepage';


LazyLoad.init();
Subpage.init();

const routes = new Router({
	homepage,
});

document.addEventListener("DOMContentLoaded", function() {
	routes.loadEvents();
});