import ContactForm from '../../Components/ContactForm/ContactForm';

export default {

	init() {
		ContactForm.init();
	},
	finalize() {

	}

}