export default {

	init() {

		var container = document.querySelector('.GoogleMaps');

		if( container ) { 

			var markerIconUrl = window.location.origin +'/wp-content/themes/woa/assets/svg/location.svg';
			var markerHref = container.getAttribute('data-url');

			var lati = container.getAttribute('data-lati');
			var long = container.getAttribute('data-long');
			var marker_title = 'World of Asia';

			var map;
			    
		    var mapOptions = {
		        zoom: 15.3,
		        center: new google.maps.LatLng(lati, long), // New York
		        mapTypeControl: false,
		        streetViewControl: false,
		        fullscreenControl : false,
		        styles: [
				    {
				        "featureType": "all",
				        "elementType": "labels",
				        "stylers": [
				            {
				                "visibility": "on"
				            }
				        ]
				    },
				    {
				        "featureType": "all",
				        "elementType": "labels.text.fill",
				        "stylers": [
				            {
				                "saturation": 36
				            },
				            {
				                "color": "#000000"
				            },
				            {
				                "lightness": 40
				            }
				        ]
				    },
				    {
				        "featureType": "all",
				        "elementType": "labels.text.stroke",
				        "stylers": [
				            {
				                "visibility": "on"
				            },
				            {
				                "color": "#000000"
				            },
				            {
				                "lightness": 16
				            }
				        ]
				    },
				    {
				        "featureType": "all",
				        "elementType": "labels.icon",
				        "stylers": [
				            {
				                "visibility": "off"
				            }
				        ]
				    },
				    {
				        "featureType": "administrative",
				        "elementType": "geometry.fill",
				        "stylers": [
				            {
				                "color": "#000000"
				            },
				            {
				                "lightness": 20
				            }
				        ]
				    },
				    {
				        "featureType": "administrative",
				        "elementType": "geometry.stroke",
				        "stylers": [
				            {
				                "color": "#000000"
				            },
				            {
				                "lightness": 17
				            },
				            {
				                "weight": 1.2
				            }
				        ]
				    },
				    {
				        "featureType": "administrative.country",
				        "elementType": "labels.text.fill",
				        "stylers": [
				            {
				                "color": "#e5c163"
				            }
				        ]
				    },
				    {
				        "featureType": "administrative.locality",
				        "elementType": "labels.text.fill",
				        "stylers": [
				            {
				                "color": "#c4c4c4"
				            }
				        ]
				    },
				    {
				        "featureType": "administrative.neighborhood",
				        "elementType": "labels.text.fill",
				        "stylers": [
				            {
				                "color": "#e5c163"
				            }
				        ]
				    },
				    {
				        "featureType": "landscape",
				        "elementType": "geometry",
				        "stylers": [
				            {
				                "color": "#000000"
				            },
				            {
				                "lightness": 20
				            }
				        ]
				    },
				    {
				        "featureType": "poi",
				        "elementType": "geometry",
				        "stylers": [
				            {
				                "color": "#000000"
				            },
				            {
				                "lightness": 21
				            },
				            {
				                "visibility": "on"
				            }
				        ]
				    },
				    {
				        "featureType": "poi.business",
				        "elementType": "geometry",
				        "stylers": [
				            {
				                "visibility": "on"
				            }
				        ]
				    },
				    {
				        "featureType": "road.highway",
				        "elementType": "geometry.fill",
				        "stylers": [
				            {
				                "color": "#e5c163"
				            },
				            {
				                "lightness": "0"
				            }
				        ]
				    },
				    {
				        "featureType": "road.highway",
				        "elementType": "geometry.stroke",
				        "stylers": [
				            {
				                "visibility": "off"
				            }
				        ]
				    },
				    {
				        "featureType": "road.highway",
				        "elementType": "labels.text.fill",
				        "stylers": [
				            {
				                "color": "#ffffff"
				            }
				        ]
				    },
				    {
				        "featureType": "road.highway",
				        "elementType": "labels.text.stroke",
				        "stylers": [
				            {
				                "color": "#e5c163"
				            }
				        ]
				    },
				    {
				        "featureType": "road.arterial",
				        "elementType": "geometry",
				        "stylers": [
				            {
				                "color": "#000000"
				            },
				            {
				                "lightness": 18
				            }
				        ]
				    },
				    {
				        "featureType": "road.arterial",
				        "elementType": "geometry.fill",
				        "stylers": [
				            {
				                "color": "#575757"
				            }
				        ]
				    },
				    {
				        "featureType": "road.arterial",
				        "elementType": "labels.text.fill",
				        "stylers": [
				            {
				                "color": "#ffffff"
				            }
				        ]
				    },
				    {
				        "featureType": "road.arterial",
				        "elementType": "labels.text.stroke",
				        "stylers": [
				            {
				                "color": "#2c2c2c"
				            }
				        ]
				    },
				    {
				        "featureType": "road.local",
				        "elementType": "geometry",
				        "stylers": [
				            {
				                "color": "#000000"
				            },
				            {
				                "lightness": 16
				            }
				        ]
				    },
				    {
				        "featureType": "road.local",
				        "elementType": "labels.text.fill",
				        "stylers": [
				            {
				                "color": "#999999"
				            }
				        ]
				    },
				    {
				        "featureType": "transit",
				        "elementType": "geometry",
				        "stylers": [
				            {
				                "color": "#000000"
				            },
				            {
				                "lightness": 19
				            }
				        ]
				    },
				    {
				        "featureType": "water",
				        "elementType": "geometry",
				        "stylers": [
				            {
				                "color": "#000000"
				            },
				            {
				                "lightness": 17
				            }
				        ]
				    }
				]
		    };
		    
		    var mapElement = container;

		    map = new google.maps.Map(mapElement, mapOptions);

		    var mainMarkerIcon = { 
		    	url: markerIconUrl,
		    	scaledSize: new google.maps.Size(48, 48), // scaled size
			    origin: new google.maps.Point(0,0), // origin
			    anchor: new google.maps.Point(24, 48) // anchor
		    };

		    var marker = new google.maps.Marker({
		        position: new google.maps.LatLng(lati, long),
		        title: marker_title,
		        map: map,
		        icon : mainMarkerIcon,
		    });
			
		    if( !markerHref ) {

		    	markerHref = 'http://maps.google.com/maps/place/'+lati+','+long;

		    }

		    google.maps.event.addListener(marker, 'click', function() {
			    	
			    	window.open(  markerHref, '_blank');

			});

				

		}

	},
	finalize() {

	}

}