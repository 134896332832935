import ImageLink from '../../Components/ImageLink/ImageLink';
import $ from 'jquery';

export default {

	init() {
		
		ImageLink.init();

		var container = document.querySelector('.HeadSection');

		if( container ) {

			function headSectionScroll( container ) {

				var button = container.querySelector('.headSection__content .content .Button');
				var buttonUrl = button.getAttribute('href').split('#')[0];
				var buttonHash = button.getAttribute('href').split('#')[1];
				var section = null;

				var header = document.querySelector('.Header');

				if( 
					buttonUrl =='' && 
					buttonHash &&
					document.querySelector('#'+buttonHash)
				) { 

					section = document.querySelector('#'+buttonHash);

					button.addEventListener('click', function(e) {
						
						e.preventDefault();

						if( window.innerWidth <= 960 ) {

							$('html ,body').animate({'scrollTop': (section.offsetTop - header.offsetHeight) }, 'smooth');

						} else {

							$('html ,body').animate({'scrollTop': section.offsetTop }, 'smooth');

						}

					});

				}

			}

			headSectionScroll( container );

		}

	}

}