import HeadSection from '../../Sections/HeadSection/HeadSection';
import SalesDepartment from '../../Sections/SalesDepartment/SalesDepartment';
import GoogleMaps from '../../Sections/GoogleMaps/GoogleMaps';
import Slaider from '../../Sections/Slaider/Slaider';

export default {
	
	init() {

		HeadSection.init();
		SalesDepartment.init();
		GoogleMaps.init();
		Slaider.init();
		
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
