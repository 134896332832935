export default {

	init(){

		var container = document.querySelector('.Header .LanguagesList');

		if( container ) {

			function languagesSwitch( container ) {

				var list = container.querySelector('.languagesList__list');
				var listItems = list.querySelectorAll('li');

				container.addEventListener('click', function(e){

					e.stopPropagation();

					if( !container.classList.contains('open') ) {

						container.classList.add('open');

					} else {

						container.classList.remove('open');

					}

				});

				document.addEventListener('click', function(){

					if( container.classList.contains('open') ) {

						container.classList.remove('open');

					}

				});

				

			}

			languagesSwitch( container );

		}

	},
	finalize() {

	}

}